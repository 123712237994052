import { Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Page from "../components/page";
import LoadingBackdrop from "../components/loading-backdrop";
import { Customer } from "../models/customer";
import { customersApi } from "../services/api/customers";
import { ordersApi } from "../services/api/orders";
import OrderForm, {
  OrderFormValues,
  OrderRecurType,
} from "../components/order-form";
import { productsApi } from "../services/api/products";
import { Product } from "../models/product";
import { UserContext, ErrorContext } from "../components/app-routes";
import { formatCurrency, toCurrency } from "./currency";
import { v4 } from "uuid";
import mixpanel from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";
import { Order } from "../models/order";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

interface Data {
  customers: Customer[];
  products: Product[];
}

export default function AddOrderPage() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState<Data | null>(null);
  const [busy, setBusy] = useState(false);
  const [saved, setSaved] = useState(false);
  const { grower } = useContext(UserContext);
  const { handleError } = useContext(ErrorContext);
  const { state } = useLocation<{ order: Order }>();

  if (!grower) {
    throw new Error("Invalid UserContext");
  }

  const initialValues: OrderFormValues = state
    ? {
        customerId: state.order.customerId.toString(),
        dateType: "harvestDate",
        date: null,
        recur: {
          id: "",
          type: OrderRecurType.None,
          endDate: null,
        },
        orderProducts: state.order.orderProducts.map((v) => ({
          id: v4(),
          productId: v.productId.toString(),
          productSizeId: v.productSizeId.toString(),
          quantity: v.quantity.toString(),
          price: formatCurrency(v.price, grower.CountryISO, {
            symbol: "",
            separator: "",
          }),
        })),
        notes: state.order.notes,
      }
    : {
        customerId: "",
        dateType: "harvestDate",
        date: null,
        recur: {
          id: "",
          type: OrderRecurType.None,
          endDate: null,
        },
        orderProducts: [
          {
            id: v4(),
            productId: "",
            productSizeId: "",
            quantity: "",
            price: "",
          },
        ],
        notes: "",
      };

  useEffect(() => {
    async function load() {
      setBusy(true);
      try {
        const data = await Promise.all([
          customersApi.getAll(),
          productsApi.getAll(),
        ]);
        setData({
          customers: data[0],
          products: data[1],
        });
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    load();
  }, []);

  const handleSubmit = async (values: OrderFormValues) => {
    if (!values.date) {
      throw new Error("Invalid state");
    }
    setBusy(true);

    try {
      let harvestDate: string;

      if (values.dateType === "startDate") {
        const products = await productsApi.getAll();

        const daysToHarvest = Math.max(
          ...values.orderProducts.flatMap((oPs) => {
            const product = products.find(
              (v) => v.id === parseInt(oPs.productId)
            );

            if (!product) {
              throw new Error("Invalid state");
            }

            return product.productCrops.map((v) => v.crop.daysToMaturity);
          })
        );

        harvestDate = values.date
          .plus({
            day: daysToHarvest,
          })
          .toISODate();
      } else {
        harvestDate = values.date.toISODate();
      }

      await ordersApi.add({
        id: 0,
        customerId: parseInt(values.customerId),
        harvestDate: harvestDate,
        recur:
          values.recur?.type !== OrderRecurType.None && values.recur?.endDate
            ? {
                id: (null as unknown) as number,
                type: values.recur.type,
                endDate: values.recur.endDate.toISODate(),
              }
            : undefined,
        orderProducts: values.orderProducts.map((oP) => ({
          productId: parseInt(oP.productId),
          productSizeId: parseInt(oP.productSizeId),
          quantity: parseInt(oP.quantity),
          price: toCurrency(oP.price, grower.CountryISO).intValue,
        })),
        customer: (null as unknown) as Customer,
        notes: values.notes,
      });

      mixpanel.track("Add Order");

      setSaved(true);

      history.replace("/home/orders");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const handleOnClickCancel = () => {
    goBackOrTo(history, "/home/orders");
  };

  return (
    <Page title={"Add Order"}>
      {data && (
        <OrderForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          customers={data.customers}
          products={data.products}
          saved={saved}
        >
          <Box mt={2} className={classes.actions}>
            <Button
              variant="outlined"
              disabled={busy}
              onClick={handleOnClickCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={busy}
            >
              Add
            </Button>
          </Box>
        </OrderForm>
      )}
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
