import React, { useState, Fragment, useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ListItemLink } from "../components/list-items";
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Hidden,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import CropsPage from "./crops-page";
import AddCrop from "../components/add-crop";
import CropPage from "./crop-page";
import CustomersPage from "./customers-page";
import Products from "./products-page";
import AddProductPage from "./add-product-page";
import CustomerPage from "./customer-page";
import ProductPage from "./product-page";
import OrdersPage from "./orders-page";
import AddOrderPage from "./add-order-page";
import OrderPage from "./order-page";
import TasksPage from "./tasks-page";
import AddCustomerPage from "./add-customer-page";
import ReportsPage from "./reports-page";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import logo from "../assets/logo.png";
import LoadingBackdrop from "../components/loading-backdrop";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import { ErrorContext, UserContext } from "../components/app-routes";
import SeedsPage from "./seeds-page";
import GrainIcon from "@mui/icons-material/Grain";
import InventoryIcon from "@mui/icons-material/Inventory";
import PeopleIcon from "@mui/icons-material/People";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DeliveriesPage from "./deliveries-page";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import FrillWidget from "./frill-widget";
import mixpanel from "mixpanel-browser";
import YouTube, { YouTubeProps } from "react-youtube";
import { onboardingsApi as onboardingApi } from "../services/api/onboardings";
import { Link as RouterLink } from "react-router-dom";
import { Grower } from "../models/grower";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SeedPage from "./seed-page";
import AddSeedPage from "./add-seed-page";
import SettingsPage from "./settings-page";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    justifyContent: "end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logoContainer: {
    padding: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  onboardingDialogContentText: {
    textAlign: "center",
  },
  verifyEmailContainer: {
    display: "block",
    backgroundColor: theme.palette.primary.light,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
  },
  verifyEmailButton: {
    color: "white",
  },
  businessPlanLinkContainer: {
    display: "flex",
  },
  businessPlanIcon: {
    marginLeft: theme.spacing(2),
  },
}));

export default function HomePage(props: { onLogOut: () => void }) {
  const context = useContext(UserContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [
    profileMenuAnchorEl,
    setProfileMenuAnchorEl,
  ] = useState<Element | null>(null);
  const openProfileMenu = Boolean(profileMenuAnchorEl);
  const [
    supportMenuAnchorEl,
    setSupportMenuAnchorEl,
  ] = useState<Element | null>(null);
  const openSupportMenu = Boolean(supportMenuAnchorEl);
  const [openOnboardingDialog, setOpenOnboardingDialog] = useState(
    context.grower?.Onboarding.NewUserVideo !== true
  );
  const classes = useStyles();
  const { handleError } = useContext(ErrorContext);
  const grower = context.grower;
  const history = useHistory();

  if (!grower) {
    throw new Error("Invalid grower");
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickLink = () => {
    setMobileOpen(false);
  };

  const handleClickProfileMenu: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleClickSupportMenu: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setSupportMenuAnchorEl(event.currentTarget);
  };

  const handleCloseSupportMenu = () => {
    setSupportMenuAnchorEl(null);
  };

  const handleClickLogOut = () => {
    setProfileMenuAnchorEl(null);
    props.onLogOut();
  };

  const handleClickSupport = () => {
    window.open("https://seedleaf.freshdesk.com/support/home", "_blank");

    mixpanel.track("Click Support");

    handleCloseSupportMenu();
  };

  const handleCloseOnboardingDialog = () => {
    setOpenOnboardingDialog(false);
    mixpanel.track("Snooze Onboarding Dialog");
  };

  const handleCompleteOnboardingDialog = async () => {
    setBusy(true);
    try {
      await onboardingApi.patch({
        NewUserVideo: true,
      });
      mixpanel.track("Complete Onboarding Dialog Step", {
        step: "New User Video",
      });
      mixpanel.track("Complete Onboarding Dialog");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
      setOpenOnboardingDialog(false);
    }
  };

  const handleOnPlayOnboardingVideo = () => {
    mixpanel.track("Play New User Video");
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const DrawerListItemLinks = (props: { grower: Grower }) => (
    <Fragment>
      <div className={classes.logoContainer}>
        <img alt="logo" src={logo} width="150" />
      </div>
      <List>
        <ListItemLink
          onClick={handleClickLink}
          to="/home/tasks"
          primary="Tasks"
          icon={<ScheduleIcon />}
        />
        <ListItemLink
          onClick={handleClickLink}
          to="/home/orders"
          primary="Orders"
          icon={<ListIcon />}
        />
        <ListItemLink
          onClick={handleClickLink}
          to="/home/seeds"
          primary="Seeds"
          icon={<GrainIcon />}
        />
        <ListItemLink
          onClick={handleClickLink}
          to="/home/crops"
          primary="Crops"
          icon={<LocalFloristIcon />}
        />
        <ListItemLink
          onClick={handleClickLink}
          to="/home/products"
          primary="Products"
          icon={<InventoryIcon />}
        />
        <ListItemLink
          onClick={handleClickLink}
          to="/home/customers"
          primary="Customers"
          icon={<PeopleIcon />}
        />
        <ListItemLink
          onClick={handleClickLink}
          to="/home/deliveries"
          primary={
            <div className={classes.businessPlanLinkContainer}>
              Deliveries{" "}
              {props.grower.Plan === "hobby" ? (
                <LockOutlinedIcon
                  className={classes.businessPlanIcon}
                  fontSize="small"
                />
              ) : null}
            </div>
          }
          icon={<DirectionsBikeIcon />}
        />
        <ListItemLink
          onClick={handleClickLink}
          to="/home/reports"
          primary={
            <div className={classes.businessPlanLinkContainer}>
              Reports{" "}
              {props.grower.Plan === "hobby" ? (
                <LockOutlinedIcon
                  className={classes.businessPlanIcon}
                  fontSize="small"
                />
              ) : null}
            </div>
          }
          icon={<InsertChartIcon />}
        />
        <Divider />
        <ListItemLink
          onClick={handleClickLink}
          to="/home/settings"
          primary="Settings"
          icon={<SettingsIcon />}
        />
      </List>
    </Fragment>
  );

  const youTubeOpts: YouTubeProps["opts"] = {
    playerVars: {
      rel: 0,
      cc_load_policy: 1,
      cc_lang_pref: "en",
    },
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        {context.user.EmailVerified === false && (
          <div className={classes.verifyEmailContainer}>
            <Button
              className={classes.verifyEmailButton}
              component={RouterLink}
              to="/verify-email"
              size="small"
              variant="contained"
              color="primary"
            >
              Please verify your email
            </Button>
          </div>
        )}
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.title}></div>
          <div>
            <IconButton
              size="medium"
              onClick={handleClickSupportMenu}
              color="inherit"
            >
              <HelpIcon />
            </IconButton>

            <Menu
              anchorEl={supportMenuAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorReference={"anchorEl"}
              keepMounted
              open={openSupportMenu}
              onClose={handleCloseSupportMenu}
            >
              <MenuItem onClick={handleClickSupport}>
                {" "}
                <ListItemIcon>
                  <ImportContactsIcon />
                </ListItemIcon>
                <ListItemText primary="Support" />
              </MenuItem>
            </Menu>

            <IconButton
              size="medium"
              onClick={handleClickProfileMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>

            <Menu
              anchorEl={profileMenuAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorReference={"anchorEl"}
              keepMounted
              open={openProfileMenu}
              onClose={handleCloseProfileMenu}
            >
              <MenuItem onClick={handleClickLogOut}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={"left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerListItemLinks grower={grower}></DrawerListItemLinks>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DrawerListItemLinks grower={grower}></DrawerListItemLinks>
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/home/seeds">
            <SeedsPage />
          </Route>
          <Route exact path="/home/seeds/add">
            <AddSeedPage />
          </Route>
          <Route exact path="/home/seeds/:id">
            <SeedPage />
          </Route>
          <Route exact path="/home/crops">
            <CropsPage></CropsPage>
          </Route>
          <Route exact path="/home/crops/add">
            <AddCrop></AddCrop>
          </Route>
          <Route path="/home/crops/:id">
            <CropPage></CropPage>
          </Route>
          <Route exact path="/home/customers">
            <CustomersPage></CustomersPage>
          </Route>
          <Route exact path="/home/customers/add">
            <AddCustomerPage />
          </Route>
          <Route path="/home/customers/:id">
            <CustomerPage />
          </Route>
          <Route exact path="/home/products">
            <Products></Products>
          </Route>
          <Route exact path="/home/products/add">
            <AddProductPage></AddProductPage>
          </Route>
          <Route exact path="/home/products/:id">
            <ProductPage />
          </Route>
          <Route exact path="/home/orders">
            <OrdersPage />
          </Route>
          <Route exact path="/home/orders/add">
            <AddOrderPage />
          </Route>
          <Route exact path="/home/orders/:id">
            <OrderPage />
          </Route>
          <Route exact path="/home/tasks">
            <TasksPage />
          </Route>
          <Route exact path="/home/reports">
            <ReportsPage />
          </Route>
          <Route path="/home/deliveries">
            <DeliveriesPage />
          </Route>
          <Route path="/home/settings">
            <SettingsPage />
          </Route>
          <Route path="/home">
            <Redirect to="/home/tasks" />
          </Route>
        </Switch>
      </main>
      <LoadingBackdrop open={busy} />
      <FrillWidget />
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openOnboardingDialog}
        onClose={handleCloseOnboardingDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Getting Starting With SeedLeaf
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.onboardingDialogContentText}>
            <YouTube
              onPlay={handleOnPlayOnboardingVideo}
              videoId="dM7KK-upBGk"
              opts={youTubeOpts}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOnboardingDialog}>
            Snooze until next session
          </Button>
          <Button onClick={handleCompleteOnboardingDialog} color="primary">
            Complete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
