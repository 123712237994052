import { apiProvider } from "./utilities/provider";
import { Grower } from "../../models/grower";
import { JsonDocument } from "./utilities/json";

export const growersApi = {
  create: (d: {
    name: string;
    unitSystem: string;
    countryIso: string;
    currencyIso: string;
    promotionCode: string;
  }): Promise<void> =>
    apiProvider.post("growers", {
      data: d,
    }),
  get: (): Promise<Grower[]> =>
    apiProvider.getAll("growers").then((d: JsonDocument<Grower[]>) => d.data),
};
