import React, { useEffect, useState, useContext, useMemo } from "react";
import Page from "../components/page";
import LoadingBackdrop from "../components/loading-backdrop";
import { tasksApi } from "../services/api/tasks";
import { DateTime, DateTimeFormatOptions, Duration } from "luxon";
import { CompletedTask, TaskType, Tasks } from "../models/tasks";
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Autocomplete,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Size } from "../models/size";
import { sizesApi } from "../services/api/sizes";
import { unitSystemLabel, unitSystemLabelOther } from "./crops-page";
import { UserContext, ErrorContext } from "../components/app-routes";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Crop } from "../models/crop";
import { cropsApi } from "../services/api/crops";
import {
  CropTask,
  HarvestTask,
  PackageTask,
  SoakTask,
  SowTask,
  Task,
} from "../models/task";
import mixpanel from "mixpanel-browser";
import { UpgradePlanDialog } from "../components/upgrade-plan-dialog";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { Tooltip } from "../components/tooltip";
import { AppTextField } from "../components/text-field";
import {
  FastField,
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from "formik";
import * as yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { validationMessages } from "../utils/validation/messages";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../components/confirm-dialog";
import { otherUnitSystem, ozToGrams } from "../components/crop-form";
import humanizeDuration from "humanize-duration";

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  taskTitle: {
    paddingTop: theme.spacing(4),
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
  },
  table: {
    minWidth: 650,
  },
  formControl: {
    marginLeft: theme.spacing(2),
    width: "100px",
  },
  weeklyTableCell: {
    width: "14.3%",
  },
  weeklyTableHarvestDiv: {
    background: "#56d787",
    minHeight: "60px",
    padding: theme.spacing(1),
    borderRadius: "5px",
    textAlign: "center",
  },
  weeklyTableSoakBox: {
    background: "#dab894",
    minHeight: "60px",
    padding: theme.spacing(1),
    borderRadius: "5px",
    textAlign: "center",
  },
  weeklyTableSowBox: {
    background: "#EAB159",
    minHeight: "60px",
    padding: theme.spacing(1),
    borderRadius: "5px",
    textAlign: "center",
  },
  weeklyTableBlackoutBox: {
    background: "#FFF1AB",
    minHeight: "60px",
    padding: theme.spacing(1),
    borderRadius: "5px",
    textAlign: "center",
  },
  weeklyTableUncoverBox: {
    background: "#ffee93",
    minHeight: "60px",
    padding: theme.spacing(1),
    borderRadius: "5px",
    textAlign: "center",
  },
  weeklyTableBodyRow: {
    height: "80px",
  },
  weeklyDateButton: {
    textDecoration: "underline",
  },
  todayButton: {
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  exportButton: {
    marginLeft: "10px",
  },
  seedLotsSelect: {},
}));

const TaskHeader = (props: { label: string; trayTotal: number }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.taskTitle} variant="h4" color="primary">
      {props.label}
      {props.trayTotal > 0 && (
        <Typography sx={{ float: "right" }} color="black" variant="body1">
          <strong>Total Trays: {props.trayTotal}</strong>
        </Typography>
      )}
    </Typography>
  );
};

interface TaskDialogFormValues {
  date: DateTime;
  seedLots: string[];
  completedBy: string;
  notes: string;
  traysActual?: number;
  yieldActual?: number;
  packagesActual?: number;
}

const taskDialogFormSchema = yup.object({
  date: yup
    .date()
    .required(validationMessages.Required)
    .typeError(validationMessages.Date),
  seedLots: yup.array().of(yup.number()),
  completedBy: yup.string().typeError(validationMessages.String),
  notes: yup.string(),
  traysActual: yup
    .number()
    .nullable()
    .min(0, validationMessages.Min)
    .integer(validationMessages.Integer)
    .typeError(validationMessages.Number),
  yieldActual: yup
    .number()
    .nullable()
    .min(0, validationMessages.Min)
    .typeError(validationMessages.Number),
  packagesActual: yup
    .number()
    .nullable()
    .min(0, validationMessages.Min)
    .typeError(validationMessages.Number),
});

const taskLastCompletedByStorageKey = "taskLastCompletedBy";

export default function TasksPage() {
  const classes = useStyles();
  const [loadingOtherData, setLoadingOtherData] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [periodTasks, setPeriodTasks] = useState<Tasks[] | undefined>(
    undefined
  );
  const [periodTasksTag, setPeriodTasksTag] = useState(0);
  const [sizes, setSizes] = useState<Size[] | undefined>(undefined);
  const [crops, setCrops] = useState<Crop[] | undefined>(undefined);
  const [busy, setBusy] = useState(false);
  const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = useState(false);
  const context = useContext(UserContext);
  const { handleError } = useContext(ErrorContext);
  const grower = context.grower;
  const location = useLocation();
  const history = useHistory();
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [taskDialogData, setTaskDialogData] = useState<{
    task: SowTask | SoakTask | CropTask | HarvestTask | PackageTask;
    crop: Crop | null;
    type: TaskType;
    completedTask?: CompletedTask;
  } | null>(null);
  const [users, setUsers] = useState<string[] | undefined>(undefined);
  const [openUncompleteTaskPrompt, setOpenUncompleteTaskPrompt] = useState(
    false
  );
  const [uncompletingTask, setUncompletingTask] = useState<Task | undefined>(
    undefined
  );

  if (!grower) {
    throw new Error("Invalid context");
  }

  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const harvestDateLocaleOptions: DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
  };

  useEffect(() => {
    if (!searchParams.get("date")) {
      searchParams.set("date", DateTime.now().toISODate());
    }
    if (!searchParams.get("by")) {
      searchParams.set("by", "day");
    }
    history.replace({ search: searchParams.toString() });
  }, [searchParams]);

  useEffect(() => {
    async function getData() {
      setLoadingOtherData(true);
      try {
        const [sizes, crops] = await Promise.all([
          sizesApi.getAll(),
          cropsApi.getAll(),
        ]);
        setSizes(sizes);
        setCrops(crops);
      } catch (error) {
        handleError(error);
      } finally {
        setLoadingOtherData(false);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      const date = searchParams.get("date");
      const period = searchParams.get("by");
      if (!date || !period) {
        return;
      }
      setLoadingTasks(true);
      try {
        const { tasksForRange, users } = await tasksApi.getAll(
          DateTime.fromISO(date),
          period
        );
        setPeriodTasks(tasksForRange);
        setUsers(users);
      } catch (error) {
        handleError(error);
      } finally {
        setLoadingTasks(false);
      }
    }
    getData();
  }, [searchParams, periodTasksTag]);

  const handleDateChange = (date: DateTime | null) => {
    if (date === null) {
      return;
    }
    searchParams.set("date", date.toISODate());
    history.replace({ search: searchParams.toString() });
  };

  const handlePeriodChange = (event: any) => {
    if (grower.Plan === "hobby" && event.target.value === "week") {
      setOpenUpgradePlanDialog(true);
      return;
    }
    setPeriodTasks(undefined);
    searchParams.set("by", event.target.value);
    history.replace({ search: searchParams.toString() });
  };

  const handleClickDate = (date: string) => {
    searchParams.set("date", date);
    searchParams.set("by", "day");
    history.replace({ search: searchParams.toString() });
  };

  const handleClickToday = () => {
    searchParams.set("date", DateTime.now().toISODate());
    searchParams.set("by", "day");
    history.replace({ search: searchParams.toString() });
  };

  const getCrop = (cropId: number) => {
    if (!crops) {
      throw new Error("Invalid state: crops not defined");
    }
    const crop = crops.find((c) => c.id === cropId);
    if (!crop) {
      throw new Error("Invalid state: crop not found");
    }
    return crop;
  };

  const handleExportPDF = () => {
    async function post() {
      setBusy(true);
      try {
        const date = searchParams.get("date");
        if (!date) {
          throw new Error("Invalid search parameters");
        }
        await tasksApi.getPdf(DateTime.fromISO(date));

        mixpanel.track("Export Tasks Pdf");
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    if (grower.Plan === "hobby") {
      setOpenUpgradePlanDialog(true);
      return;
    }
    post();
  };

  const isByDay = () => searchParams.get("by") === "day";

  const doRequestUncompleteTask = async (task: Task) => {
    setBusy(true);

    try {
      await tasksApi.uncomplete(task.completedId);
      setPeriodTasksTag(periodTasksTag + 1);
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const doRequestToggleCompletedTask = async (
    task: Task,
    itemId: number,
    type: TaskType,
    values?: TaskDialogFormValues
  ) => {
    setBusy(true);
    try {
      const date = searchParams.get("date");
      if (!date) {
        throw new Error("Invalid search parameters");
      }
      let traysExpected: number | undefined,
        yieldExpected: number | undefined,
        harvestDate: string | undefined,
        cropName: string | undefined,
        cropNotes: string | undefined,
        cropSowingRate: number | undefined,
        cropExpectedYield: number | undefined,
        seedName: string | undefined,
        seedVariety: string | undefined,
        seedNotes: string | undefined,
        packagedProductName: string | undefined,
        packagedProductSize: string | undefined,
        packagedProductWeight: number | undefined,
        packagedQuantityExpected: number | undefined,
        packagedQuantityActual: number | undefined;

      if (grower.Workflow === "advanced") {
        if (!taskDialogData) {
          throw new Error("Invalid state");
        }

        if (type !== "package") {
          traysExpected = (task as CropTask).trays;
          harvestDate = (task as CropTask).harvestDate;
        } else {
          const pT = task as PackageTask;
          packagedProductName = pT.product.name;
          packagedProductSize =
            pT.productSize.type === "tray" ? "Tray" : pT.productSize.size?.name;
          packagedProductWeight = pT.productSize.weight;
          packagedQuantityExpected = pT.quantity;
          packagedQuantityActual = values?.packagesActual;
          harvestDate = date;
        }

        if (type === "harvest") {
          yieldExpected = (task as HarvestTask).expectedYield;
        }

        if (type === "sow") {
          if (!taskDialogData.crop) {
            throw new Error("Invalid state");
          }
          cropName = taskDialogData.crop.name;
          cropNotes = taskDialogData.crop.notes;
          cropExpectedYield = taskDialogData.crop.cutYield;
          cropSowingRate = taskDialogData.crop.seedingRate;
          seedName = taskDialogData.crop.seed?.name;
          seedVariety = taskDialogData.crop.seed?.variety;
          seedNotes = taskDialogData.crop.seed?.notes;
        }
      }

      const data: CompletedTask = {
        id: task.completed ? task.completedId : 0,
        itemId,
        date: date,
        dateActual: values?.date.toISODate(),
        type,
        seedLots: values?.seedLots?.map((l) => ({
          id: 0,
          seedLotId: parseInt(l),
        })),
        completedBy: values?.completedBy,
        notes: values?.notes,
        traysExpected,
        traysActual: values?.traysActual,
        yieldExpected,
        yieldActual: values?.yieldActual,
        harvestDate,
        cropName,
        cropNotes,
        cropExpectedYield,
        cropSowingRate,
        seedName,
        seedVariety,
        seedNotes,
        packagedProductName,
        packagedProductSize,
        packagedProductWeight,
        packagedQuantityActual,
        packagedQuantityExpected,
      };

      if (task.completed) {
        await tasksApi.update(data);
      } else {
        await tasksApi.complete(data);

        task.completed = !task.completed;

        if (task.completed) {
          mixpanel.track("Complete Task");
        }
      }

      setPeriodTasksTag(periodTasksTag + 1);

      if (values?.completedBy) {
        localStorage.setItem(taskLastCompletedByStorageKey, values.completedBy);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const handleOnClickCropTaskCompleted = async (
    task: CropTask,
    type: TaskType
  ) => {
    if (task.completed) {
      if (grower.Workflow === "advanced") {
        setOpenUncompleteTaskPrompt(true);
        setUncompletingTask(task);
        return;
      }
      doRequestUncompleteTask(task);
      return;
    }

    if (grower.Workflow === "simple") {
      doRequestToggleCompletedTask(task, task.cropId, type);
      return;
    }

    setBusy(true);

    try {
      const crop = await cropsApi.getSingle(task.cropId);

      setTaskDialogData({
        task,
        crop,
        type,
      });
      setOpenTaskDialog(true);
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const handleOnClickPackageTaskCompleted = (task: PackageTask) => {
    if (task.completed) {
      if (grower.Workflow === "advanced") {
        setOpenUncompleteTaskPrompt(true);
        setUncompletingTask(task);
        return;
      }
      doRequestUncompleteTask(task);
      return;
    }

    if (grower.Workflow === "simple") {
      doRequestToggleCompletedTask(task, task.productSize.id, "package");
      return;
    }

    setTaskDialogData({
      task: task,
      crop: null,
      type: "package",
    });
    setOpenTaskDialog(true);
  };

  const onClickCancelUpgradePlan = () => {
    setOpenUpgradePlanDialog(false);
  };

  const onSubmitTaskDialogForm = async (values: TaskDialogFormValues) => {
    if (!taskDialogData) {
      throw new Error("Invalid state");
    }

    setOpenTaskDialog(false);

    let itemId: number;

    if (taskDialogData.type === "package") {
      itemId = (taskDialogData.task as PackageTask).productSize.id;
    } else {
      itemId = taskDialogData.crop!.id;
    }
    doRequestToggleCompletedTask(
      taskDialogData.task,
      itemId,
      taskDialogData.type,
      values
    );
  };

  const completingTaskCropHasLots =
    taskDialogData && taskDialogData.crop
      ? taskDialogData?.crop.seed!.lots.filter((l) => l.archivedAt === null)
          .length > 0
      : null;

  const shouldShowSeedLots = () => {
    if (!taskDialogData) {
      throw new Error("Invalid state");
    }

    if (!completingTaskCropHasLots) {
      return false;
    }

    if (taskDialogData.type === "package") {
      return false;
    }

    if (!taskDialogData.crop) {
      throw new Error("Invalid state");
    }

    if (taskDialogData.type !== "soak" && taskDialogData.type !== "sow") {
      return false;
    }

    if (
      taskDialogData.type === "sow" &&
      taskDialogData.crop.soakTimeMinutes !== 0
    ) {
      return false;
    }

    return true;
  };

  const onClickEditCompletedTask = async (
    task: SowTask | SoakTask | CropTask | HarvestTask | PackageTask,
    tasktype: TaskType
  ) => {
    setBusy(true);

    try {
      let crop: Crop | null = null;

      if (tasktype !== "package") {
        crop = await cropsApi.getSingle((task as CropTask).cropId);
      }

      const completedTask = await tasksApi.getOne(task.completedId);

      setTaskDialogData({
        task,
        crop,
        type: tasktype,
        completedTask,
      });
      setOpenTaskDialog(true);
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const handleOnCloseConfirmUncompleteTask = (confirm?: boolean) => {
    setOpenUncompleteTaskPrompt(false);

    if (!confirm) {
      return;
    }

    if (!uncompletingTask) {
      throw new Error("Invalid state");
    }

    doRequestUncompleteTask(uncompletingTask);
  };

  return (
    <Page title={"Tasks"}>
      <MobileDatePicker<DateTime>
        value={DateTime.fromISO(
          searchParams.get("date") || DateTime.now().toISODate()
        )}
        onChange={handleDateChange}
        inputFormat="ccc LLLL dd"
        InputAdornmentProps={{
          position: "start",
        }}
        showToolbar={false}
        closeOnSelect={true}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
      <FormControl className={classes.formControl} variant={"outlined"}>
        <Select
          variant="outlined"
          value={searchParams.get("by") || "day"}
          onChange={handlePeriodChange}
        >
          <MenuItem value={"day"}>Day</MenuItem>
          <MenuItem value={"week"}>Week</MenuItem>
        </Select>
      </FormControl>
      <IconButton
        onClick={() => {
          const date = searchParams.get("date");
          if (!date) {
            throw new Error("Invalid state");
          }
          searchParams.set(
            "date",
            DateTime.fromISO(date)
              .minus({ days: isByDay() ? 1 : 7 })
              .toISODate()
          );
          history.replace({ search: searchParams.toString() });
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          const date = searchParams.get("date");
          if (!date) {
            throw new Error("Invalid state");
          }
          searchParams.set(
            "date",
            DateTime.fromISO(date)
              .plus({ days: isByDay() ? 1 : 7 })
              .toISODate()
          );
          history.replace({ search: searchParams.toString() });
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <Button color="secondary" variant="contained" onClick={handleClickToday}>
        Today
      </Button>
      {isByDay() && (
        <Button
          color="secondary"
          className={classes.exportButton}
          variant="contained"
          onClick={() => handleExportPDF()}
          endIcon={
            grower.Plan === "hobby" ? (
              <LockOutlinedIcon fontSize="small" />
            ) : null
          }
        >
          Export PDF
        </Button>
      )}
      {periodTasks && sizes && crops && (
        <>
          {isByDay() ? (
            <div>
              {periodTasks[0].soak.length > 0 ? (
                <>
                  <TaskHeader
                    label="Soak"
                    trayTotal={periodTasks[0].soak.reduce(
                      (pV, cV) => pV + cV.trays,
                      0
                    )}
                  />
                  <TableContainer className={classes.table} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>Crop</TableCell>
                          <TableCell>Seed</TableCell>
                          <TableCell>
                            Soak Rate ({unitSystemLabel(grower)} / tray)
                          </TableCell>
                          {grower?.UnitSystem === "imperial" && (
                            <TableCell>
                              Soak Rate ({unitSystemLabelOther(grower)} / tray)
                            </TableCell>
                          )}
                          <TableCell>Soak Time</TableCell>
                          <TableCell>Trays to Soak</TableCell>
                          <TableCell>
                            Total Seed Weight ({unitSystemLabel(grower)})
                          </TableCell>
                          {grower?.UnitSystem === "imperial" && (
                            <TableCell>
                              Total Seed Weight ({unitSystemLabelOther(grower)})
                            </TableCell>
                          )}
                          <TableCell>For Harvest On</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {periodTasks[0].soak
                          .sort((a, b) =>
                            getCrop(a.cropId).name.localeCompare(
                              getCrop(b.cropId).name
                            )
                          )
                          .map((task) => {
                            const crop = getCrop(task.cropId);
                            return (
                              <TableRow key={task.cropId}>
                                <TableCell>
                                  <Checkbox
                                    color="primary"
                                    checked={task.completed}
                                    onClick={() =>
                                      handleOnClickCropTaskCompleted(
                                        task,
                                        "soak"
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  {grower.Workflow === "advanced" &&
                                    task.completed && (
                                      <IconButton
                                        onClick={() =>
                                          onClickEditCompletedTask(task, "soak")
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    )}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Tooltip
                                    tooltipTitle={crop.notes}
                                    text={crop.name}
                                  />
                                </TableCell>
                                <TableCell>
                                  {crop.seed?.variety} {crop.seed?.name}
                                </TableCell>
                                <TableCell>{crop.seedingRate}</TableCell>
                                {grower.UnitSystem === "imperial" && (
                                  <TableCell>
                                    {ozToGrams(crop.seedingRate).toFixed(2)}
                                  </TableCell>
                                )}
                                <TableCell>
                                  {shortEnglishHumanizer(
                                    Duration.fromObject({
                                      minutes: crop.soakTimeMinutes,
                                    }).toMillis()
                                  )}
                                </TableCell>
                                <TableCell>
                                  {task.trays} ({task.traysExact.toFixed(1)})
                                </TableCell>
                                <TableCell>
                                  {task.totalSeedWeight.toFixed(2)}
                                </TableCell>
                                {grower.UnitSystem === "imperial" && (
                                  <TableCell>
                                    {ozToGrams(task.totalSeedWeight).toFixed(2)}
                                  </TableCell>
                                )}
                                <TableCell>
                                  <Link
                                    to={`/home/orders?from=${task.harvestDate}&to=${task.harvestDate}`}
                                  >
                                    {DateTime.fromISO(
                                      task.harvestDate
                                    ).toLocaleString(harvestDateLocaleOptions)}
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : null}

              {periodTasks[0].sow.length > 0 ? (
                <>
                  <TaskHeader
                    label="Sow and Cover"
                    trayTotal={periodTasks[0].sow.reduce(
                      (pV, cV) => pV + cV.trays,
                      0
                    )}
                  />
                  <TableContainer className={classes.table} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>Crop</TableCell>
                          <TableCell>Seed</TableCell>
                          <TableCell>
                            Sow Rate ({unitSystemLabel(grower)} / tray)
                          </TableCell>
                          {grower?.UnitSystem === "imperial" && (
                            <TableCell>
                              Sow Rate ({unitSystemLabelOther(grower)} / tray)
                            </TableCell>
                          )}
                          <TableCell>Trays to Sow</TableCell>
                          <TableCell>
                            Total Seed Weight ({unitSystemLabel(grower)})
                          </TableCell>
                          {grower?.UnitSystem === "imperial" && (
                            <TableCell>
                              Total Seed Weight ({unitSystemLabelOther(grower)})
                            </TableCell>
                          )}
                          <TableCell>For Harvest On</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {periodTasks[0].sow
                          .sort((a, b) =>
                            getCrop(a.cropId).name.localeCompare(
                              getCrop(b.cropId).name
                            )
                          )
                          .map((task) => {
                            const crop = getCrop(task.cropId);
                            return (
                              <TableRow key={task.cropId}>
                                <TableCell>
                                  <Checkbox
                                    color="primary"
                                    checked={task.completed}
                                    onClick={() =>
                                      handleOnClickCropTaskCompleted(
                                        task,
                                        "sow"
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  {grower.Workflow === "advanced" &&
                                    task.completed && (
                                      <IconButton
                                        onClick={() =>
                                          onClickEditCompletedTask(task, "sow")
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    )}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Tooltip
                                    tooltipTitle={crop.notes}
                                    text={crop.name}
                                  />
                                </TableCell>
                                <TableCell>
                                  {crop.seed?.variety} {crop.seed?.name}
                                </TableCell>
                                <TableCell>{crop.seedingRate}</TableCell>
                                {grower.UnitSystem === "imperial" && (
                                  <TableCell>
                                    {ozToGrams(crop.seedingRate).toFixed(2)}
                                  </TableCell>
                                )}
                                <TableCell>
                                  {task.trays} ({task.traysExact.toFixed(1)})
                                </TableCell>
                                <TableCell>
                                  {task.totalSeedWeight.toFixed(2)}
                                </TableCell>
                                {grower.UnitSystem === "imperial" && (
                                  <TableCell>
                                    {ozToGrams(task.totalSeedWeight).toFixed(2)}
                                  </TableCell>
                                )}
                                <TableCell>
                                  <Link
                                    to={`/home/orders?from=${task.harvestDate}&to=${task.harvestDate}`}
                                  >
                                    {DateTime.fromISO(
                                      task.harvestDate
                                    ).toLocaleString(harvestDateLocaleOptions)}
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : null}

              {periodTasks[0].blackout.length > 0 ? (
                <>
                  <TaskHeader
                    label="Move to Blackout"
                    trayTotal={periodTasks[0].blackout.reduce(
                      (pV, cV) => pV + cV.trays,
                      0
                    )}
                  />
                  <TableContainer className={classes.table} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>Crop</TableCell>
                          <TableCell>Trays to Move to Blackout</TableCell>
                          <TableCell>Sowed On</TableCell>
                          <TableCell>For Harvest On</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {periodTasks[0].blackout
                          .sort((a, b) =>
                            getCrop(a.cropId).name.localeCompare(
                              getCrop(b.cropId).name
                            )
                          )
                          .map((task) => {
                            const crop = getCrop(task.cropId);

                            return (
                              <TableRow key={task.cropId}>
                                <TableCell>
                                  <Checkbox
                                    color="primary"
                                    checked={task.completed}
                                    onClick={() =>
                                      handleOnClickCropTaskCompleted(
                                        task,
                                        "blackout"
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  {grower.Workflow === "advanced" &&
                                    task.completed && (
                                      <IconButton
                                        onClick={() =>
                                          onClickEditCompletedTask(
                                            task,
                                            "blackout"
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    )}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  <Tooltip
                                    tooltipTitle={crop.notes}
                                    text={crop.name}
                                  />
                                </TableCell>
                                <TableCell>{task.trays}</TableCell>
                                <TableCell>
                                  {" "}
                                  {DateTime.fromISO(
                                    task.sowedOn
                                  ).toLocaleString(harvestDateLocaleOptions)}
                                </TableCell>
                                <TableCell>
                                  <Link
                                    to={`/home/orders?from=${task.harvestDate}&to=${task.harvestDate}`}
                                  >
                                    {DateTime.fromISO(
                                      task.harvestDate
                                    ).toLocaleString(harvestDateLocaleOptions)}
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : null}

              {periodTasks[0].uncover.length > 0 ? (
                <>
                  <TaskHeader
                    label="Uncover to Light"
                    trayTotal={periodTasks[0].uncover.reduce(
                      (pV, cV) => pV + cV.trays,
                      0
                    )}
                  />
                  <TableContainer className={classes.table} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>Crop</TableCell>
                          <TableCell>Trays to Uncover</TableCell>
                          <TableCell>Sowed On</TableCell>
                          <TableCell>For Harvest On</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {periodTasks[0].uncover
                          .sort((a, b) =>
                            getCrop(a.cropId).name.localeCompare(
                              getCrop(b.cropId).name
                            )
                          )
                          .map((task) => {
                            const crop = getCrop(task.cropId);

                            return (
                              <TableRow key={task.cropId}>
                                <TableCell>
                                  <Checkbox
                                    color="primary"
                                    checked={task.completed}
                                    onClick={() =>
                                      handleOnClickCropTaskCompleted(
                                        task,
                                        "uncover"
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  {grower.Workflow === "advanced" &&
                                    task.completed && (
                                      <IconButton
                                        onClick={() =>
                                          onClickEditCompletedTask(
                                            task,
                                            "uncover"
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    )}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  <Tooltip
                                    tooltipTitle={crop.notes}
                                    text={crop.name}
                                  />
                                </TableCell>
                                <TableCell>{task.trays}</TableCell>
                                <TableCell>
                                  {" "}
                                  {DateTime.fromISO(
                                    task.sowedOn
                                  ).toLocaleString(harvestDateLocaleOptions)}
                                </TableCell>
                                <TableCell>
                                  <Link
                                    to={`/home/orders?from=${task.harvestDate}&to=${task.harvestDate}`}
                                  >
                                    {DateTime.fromISO(
                                      task.harvestDate
                                    ).toLocaleString(harvestDateLocaleOptions)}
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : null}

              {periodTasks[0].harvest.length > 0 ? (
                <>
                  <TaskHeader
                    label="Harvest"
                    trayTotal={periodTasks[0].harvest.reduce(
                      (pV, cV) => pV + cV.trays,
                      0
                    )}
                  />
                  <TableContainer className={classes.table} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>Crop</TableCell>
                          <TableCell>Trays to Harvest</TableCell>
                          <TableCell>
                            Expected Yield ({unitSystemLabel(grower)})
                          </TableCell>
                          <TableCell>Sowed On</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {periodTasks[0].harvest
                          .sort((a, b) =>
                            getCrop(a.cropId).name.localeCompare(
                              getCrop(b.cropId).name
                            )
                          )
                          .map((task) => (
                            <TableRow key={task.cropId}>
                              <TableCell>
                                <Checkbox
                                  color="primary"
                                  checked={task.completed}
                                  onClick={() =>
                                    handleOnClickCropTaskCompleted(
                                      task,
                                      "harvest"
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                {grower.Workflow === "advanced" &&
                                  task.completed && (
                                    <IconButton
                                      onClick={() =>
                                        onClickEditCompletedTask(
                                          task,
                                          "harvest"
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  )}
                              </TableCell>

                              <TableCell component="th" scope="row">
                                {(() => {
                                  const crop = getCrop(task.cropId);
                                  return (
                                    <Tooltip
                                      tooltipTitle={crop.notes}
                                      text={crop.name}
                                    />
                                  );
                                })()}
                              </TableCell>
                              <TableCell>{task.trays}</TableCell>
                              <TableCell>{task.expectedYield}</TableCell>
                              <TableCell>
                                {" "}
                                {DateTime.fromISO(task.sowedOn).toLocaleString(
                                  harvestDateLocaleOptions
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : null}

              {periodTasks[0].package.length > 0 ? (
                <>
                  <Typography
                    className={classes.taskTitle}
                    variant="h4"
                    color="primary"
                  >
                    Package
                  </Typography>
                  <TableContainer className={classes.table} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Size</TableCell>
                          <TableCell>
                            Weight ({unitSystemLabel(grower)})
                          </TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {periodTasks[0].package
                          .sort((a, b) =>
                            a.product.name.localeCompare(b.product.name)
                          )
                          .map((task) => (
                            <TableRow key={task.productSize.id}>
                              <TableCell>
                                <Checkbox
                                  color="primary"
                                  checked={task.completed}
                                  onClick={() =>
                                    handleOnClickPackageTaskCompleted(task)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                {grower.Workflow === "advanced" &&
                                  task.completed && (
                                    <IconButton
                                      onClick={() =>
                                        onClickEditCompletedTask(
                                          task,
                                          "package"
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  )}
                              </TableCell>

                              <TableCell component="th" scope="row">
                                <Tooltip
                                  tooltipTitle={task.product.notes}
                                  text={task.product.name}
                                />
                              </TableCell>

                              <TableCell>
                                {task.productSize.type === "tray"
                                  ? "Tray"
                                  : task.productSize.size!.name}
                              </TableCell>

                              <TableCell>{task.productSize.weight}</TableCell>

                              <TableCell>{task.quantity}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : null}

              {periodTasks[0].soak.length === 0 &&
                periodTasks[0].sow.length === 0 &&
                periodTasks[0].uncover.length === 0 &&
                periodTasks[0].blackout.length === 0 &&
                periodTasks[0].harvest.length === 0 &&
                periodTasks[0].package.length === 0 && (
                  <Box pt={2}>
                    <Typography variant="body1">No tasks today.</Typography>
                  </Box>
                )}
            </div>
          ) : (
            <Box pt={2}>
              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    {periodTasks.map((tasks) => (
                      <TableCell
                        className={classes.weeklyTableCell}
                        align={"center"}
                      >
                        <Button
                          color="secondary"
                          variant={"text"}
                          onClick={() => handleClickDate(tasks.date)}
                          className={
                            DateTime.fromISO(tasks.date).equals(
                              DateTime.now().startOf("day")
                            )
                              ? classes.todayButton
                              : undefined
                          }
                        >
                          <Typography
                            className={classes.weeklyDateButton}
                            variant={"caption"}
                          >
                            {DateTime.fromISO(tasks.date)
                              .toLocaleString({
                                weekday: "short",
                              })
                              .replace(".", "")}
                            <br />
                            {DateTime.fromISO(tasks.date).toLocaleString({
                              day: "numeric",
                            })}
                          </Typography>
                        </Button>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.weeklyTableBodyRow}>
                    {periodTasks.map((tasks) => (
                      <TableCell>
                        {tasks.harvest.length > 0 && (
                          <Box className={classes.weeklyTableHarvestDiv}>
                            <Typography variant={"caption"}>
                              Harvest
                              <br />
                              {tasks.harvest.reduce(
                                (total, task) => total + task.trays,
                                0
                              )}{" "}
                              tray(s)
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow className={classes.weeklyTableBodyRow}>
                    {periodTasks.map((tasks) => (
                      <TableCell>
                        {tasks.soak.length > 0 && (
                          <Box className={classes.weeklyTableSoakBox}>
                            <Typography variant={"caption"}>
                              Soak
                              <br />
                              {tasks.soak.reduce(
                                (total, task) => total + task.trays,
                                0
                              )}{" "}
                              tray(s)
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow className={classes.weeklyTableBodyRow}>
                    {periodTasks.map((tasks) => (
                      <TableCell>
                        {tasks.sow.length > 0 && (
                          <Box className={classes.weeklyTableSowBox}>
                            <Typography variant={"caption"}>
                              Sow
                              <br />
                              {tasks.sow.reduce(
                                (total, task) => total + task.trays,
                                0
                              )}{" "}
                              tray(s)
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow className={classes.weeklyTableBodyRow}>
                    {periodTasks.map((tasks) => (
                      <TableCell>
                        {tasks.blackout.length > 0 && (
                          <Box className={classes.weeklyTableBlackoutBox}>
                            <Typography variant={"caption"}>
                              Blackout
                              <br />
                              {tasks.blackout.reduce(
                                (total, task) => total + task.trays,
                                0
                              )}{" "}
                              tray(s)
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow className={classes.weeklyTableBodyRow}>
                    {periodTasks.map((tasks) => (
                      <TableCell>
                        {tasks.uncover.length > 0 && (
                          <Box className={classes.weeklyTableUncoverBox}>
                            <Typography variant={"caption"}>
                              Uncover
                              <br />
                              {tasks.uncover.reduce(
                                (total, task) => total + task.trays,
                                0
                              )}{" "}
                              tray(s)
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          )}
        </>
      )}
      <LoadingBackdrop open={loadingOtherData || loadingTasks || busy} />
      <UpgradePlanDialog
        open={openUpgradePlanDialog}
        onClickCancel={onClickCancelUpgradePlan}
      />
      {openTaskDialog && taskDialogData && (
        <Dialog
          open={openTaskDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth={true}
        >
          <Formik
            initialValues={
              taskDialogData.task.completed && taskDialogData.completedTask
                ? {
                    date: taskDialogData.completedTask.dateActual
                      ? DateTime.fromISO(
                          taskDialogData.completedTask.dateActual
                        )
                      : DateTime.now().startOf("day"),
                    seedLots: taskDialogData.completedTask.seedLots
                      ? taskDialogData.completedTask.seedLots.map((v) =>
                          v.seedLot!.id.toString()
                        )
                      : [],
                    completedBy: taskDialogData.completedTask.completedBy || "",
                    notes: taskDialogData.completedTask.notes || "",
                    yieldActual: taskDialogData.completedTask.yieldActual,
                    traysActual: taskDialogData.completedTask.traysActual,
                    packagesActual:
                      taskDialogData.completedTask.packagedQuantityActual,
                  }
                : {
                    date: DateTime.fromISO(searchParams.get("date")!),
                    seedLots: shouldShowSeedLots()
                      ? taskDialogData.crop?.seed?.lots.filter(
                          (l) => l.archivedAt === null
                        ).length === 1
                        ? taskDialogData.crop?.seed?.lots
                            .filter((l) => l.archivedAt === null)
                            .map((l) => l.id.toString())
                        : [""]
                      : [],
                    completedBy:
                      localStorage.getItem(taskLastCompletedByStorageKey) || "",
                    notes: "",
                    yieldActual:
                      taskDialogData.type === "harvest"
                        ? (taskDialogData.task as HarvestTask).expectedYield
                        : undefined,
                    traysActual:
                      taskDialogData.type !== "package"
                        ? (taskDialogData.task as CropTask).trays
                        : undefined,
                    packagesActual:
                      taskDialogData.type === "package"
                        ? (taskDialogData.task as PackageTask).quantity
                        : undefined,
                  }
            }
            validationSchema={taskDialogFormSchema}
            onSubmit={(v, helpers) => {
              if (shouldShowSeedLots() && !v.seedLots[v.seedLots.length - 1]) {
                helpers.setFieldError(
                  `seedLots[${v.seedLots.length - 1}]`,
                  validationMessages.Required
                );
                return;
              }
              onSubmitTaskDialogForm(v);
            }}
          >
            {(formProps: FormikProps<TaskDialogFormValues>) => {
              return (
                <>
                  <Form noValidate autoComplete="off">
                    <DialogTitle id="alert-dialog-title">
                      {taskDialogData.task.completed
                        ? "Edit Task"
                        : "Complete Task"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description"></DialogContentText>

                      <Box mt={2}>
                        <Field name="date">
                          {(fieldProps: FieldProps) => (
                            <DatePicker<DateTime>
                              value={fieldProps.field.value}
                              onChange={(date) => {
                                formProps.setFieldValue("date", date);
                              }}
                              label="Date Completed"
                              InputAdornmentProps={{
                                position: "start",
                              }}
                              inputFormat="yyyy/LL/dd"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={
                                    fieldProps.meta.touched &&
                                    fieldProps.meta.error
                                  }
                                  error={
                                    !!fieldProps.meta.error &&
                                    fieldProps.meta.touched
                                  }
                                  fullWidth
                                  variant="outlined"
                                  name={fieldProps.field.name}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "YYYY/MM/DD",
                                  }}
                                />
                              )}
                            />
                          )}
                        </Field>
                      </Box>

                      {shouldShowSeedLots() && (
                        <FieldArray
                          name="seedLots"
                          render={(arrayHelpers) => {
                            return (
                              <>
                                {formProps.values.seedLots.map((_, i) => {
                                  return (
                                    <div key={`seedLots[${i}]`}>
                                      <Field name={`seedLots[${i}]`}>
                                        {(fieldProps: FieldProps) => (
                                          <AppTextField
                                            {...fieldProps}
                                            select
                                            label={`Seed Lot ${i + 1}`}
                                          >
                                            {taskDialogData!
                                              .crop!.seed!.lots.filter(
                                                (l) => l.archivedAt === null
                                              )
                                              .filter(
                                                (l) =>
                                                  l.id.toString() ===
                                                    fieldProps.field.value ||
                                                  !formProps.values.seedLots.includes(
                                                    l.id.toString()
                                                  )
                                              )
                                              .map((l) => (
                                                <MenuItem
                                                  key={l.id}
                                                  value={l.id.toString()}
                                                >
                                                  {l.number}
                                                </MenuItem>
                                              ))}
                                          </AppTextField>
                                        )}
                                      </Field>
                                    </div>
                                  );
                                })}
                                {formProps.values.seedLots.length <
                                  taskDialogData!.crop!.seed!.lots.filter(
                                    (l) => l.archivedAt === null
                                  ).length && (
                                  <Button
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    Add lot
                                  </Button>
                                )}
                                {formProps.values.seedLots.length > 1 && (
                                  <Button
                                    color="primary"
                                    startIcon={<RemoveIcon />}
                                    onClick={() => arrayHelpers.pop()}
                                  >
                                    Remove lot
                                  </Button>
                                )}
                              </>
                            );
                          }}
                        />
                      )}

                      <Autocomplete
                        options={users || []}
                        onChange={(_e, value) =>
                          formProps.setFieldValue("completedBy", value)
                        }
                        value={formProps.initialValues.completedBy}
                        renderInput={(params) => (
                          <Field
                            {...params}
                            label="Completed By"
                            component={AppTextField}
                            name="completedBy"
                            autoFocus
                          />
                        )}
                        freeSolo
                      />

                      {taskDialogData.type !== "package" && (
                        <FastField
                          name="traysActual"
                          type="number"
                          label="Tray Count"
                          component={AppTextField}
                        ></FastField>
                      )}

                      {taskDialogData.type === "harvest" && (
                        <FastField
                          name="yieldActual"
                          type="number"
                          label={`Actual Yield (${unitSystemLabel(grower)})`}
                          component={AppTextField}
                        ></FastField>
                      )}

                      {taskDialogData.type === "package" && (
                        <FastField
                          name="packagesActual"
                          type="number"
                          label="Actual Quantity"
                          component={AppTextField}
                        ></FastField>
                      )}

                      <FastField name="notes">
                        {(fieldProps: FieldProps) => (
                          <AppTextField
                            {...fieldProps}
                            multiline
                            label="Notes"
                            maxRows={4}
                            rows={4}
                          />
                        )}
                      </FastField>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        disabled={busy}
                        onClick={() => setOpenTaskDialog(false)}
                      >
                        Cancel
                      </Button>
                      <Button disabled={busy} type="submit" color="primary">
                        Ok
                      </Button>
                    </DialogActions>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Dialog>
      )}
      <ConfirmDialog
        open={openUncompleteTaskPrompt}
        title="Warning"
        description={
          <div>
            Uncompleting this Task will permanentely delete any data you have
            entered for this specific Task. You can <b>edit</b> this specific
            Task by clicking on the pencil icon to the right of this checkbox.
            <br />
            <br /> Do you want to proceed with uncompleting this Task?
          </div>
        }
        onClose={handleOnCloseConfirmUncompleteTask}
      />
    </Page>
  );
}
