import React, { useState, useContext, useEffect } from "react";
import Page from "../components/page";
import {
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  IconButton,
  Box,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DateTime } from "luxon";
import LoadingBackdrop from "../components/loading-backdrop";
import { UserContext, ErrorContext } from "../components/app-routes";
import { formatCurrency } from "./currency";
import { deliveriesApi, Delivery } from "../services/api/deliveries";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import mixpanel from "mixpanel-browser";
import { UpgradePlanDialog } from "../components/upgrade-plan-dialog";
import { useHistory } from "react-router-dom";
import { unitSystemLabel } from "./crops-page";
import { MobileDatePicker } from "@mui/x-date-pickers";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  customerRow: {
    backgroundColor: "#d9d9d9",
  },
  headerCell: {
    fontWeight: "bolder",
  },
  dataHeaderRow: {
    backgroundColor: "#efefef",
  },
  productCell: {
    width: "25%",
  },
  sizeCell: {},
  secondaryCell: {},
  checkBoxCell: {
    width: "30px",
  },
  exportButton: {
    marginLeft: "10px",
  },
});

export default function DeliveriesPage() {
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const [date, setDate] = useState(DateTime.now());
  const [deliveries, setDeliveries] = useState<Delivery[] | undefined>(
    undefined
  );
  const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = useState(false);
  const context = useContext(UserContext);
  const { handleError } = useContext(ErrorContext);
  const grower = context.grower;
  let groupedDeliveries: { [key: string]: Delivery[] } | undefined = undefined;
  const history = useHistory();

  if (!grower) {
    throw new Error("Invalid context");
  }

  if (deliveries) {
    groupedDeliveries = groupBy(deliveries, "customerId");
  }

  useEffect(() => {
    mixpanel.track("View Deliveries Page");
  }, []);

  useEffect(() => {
    if (grower.Plan === "business") {
      return;
    }
    setOpenUpgradePlanDialog(true);
  }, []);

  useEffect(() => {
    if (grower.Plan !== "business") {
      return;
    }

    async function getData() {
      setBusy(true);
      try {
        setDeliveries(await deliveriesApi.getAll(date));
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getData();
  }, [date]);

  const handleDateChange = (date: DateTime | null) => {
    if (date === null) {
      return;
    }
    setDate(date);
  };

  const handleExportPDF = () => {
    async function post() {
      setBusy(true);
      try {
        await deliveriesApi.getPdf(date);

        mixpanel.track("Export Deliveries Pdf");
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    post();
  };

  const onClickCancelUpgradePlan = () => {
    history.replace("/home");
  };

  return (
    <Page title={"Deliveries"}>
      <MobileDatePicker<DateTime>
        inputFormat="ccc, LLLL dd, y"
        value={date}
        onChange={handleDateChange}
        InputAdornmentProps={{
          position: "start",
        }}
        showToolbar={false}
        closeOnSelect={true}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
      <IconButton onClick={() => setDate(date.minus({ days: 1 }))} size="large">
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton onClick={() => setDate(date.plus({ days: 1 }))} size="large">
        <ArrowForwardIosIcon />
      </IconButton>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setDate(DateTime.now().startOf("day"))}
      >
        Today
      </Button>
      <Button
        color="secondary"
        className={classes.exportButton}
        variant="contained"
        onClick={() => handleExportPDF()}
      >
        Export PDF
      </Button>
      {groupedDeliveries && (
        <Box pt={2}>
          {Object.keys(groupedDeliveries).length > 0 ? (
            <TableContainer className={classes.table} component={Paper}>
              <Table size="small">
                <TableBody>
                  {Object.keys(groupedDeliveries)
                    // sort by delivery route
                    .sort((customerIdA: string, customerIdB: string) => {
                      return groupedDeliveries
                        ? groupedDeliveries[
                            customerIdA
                          ][0].customerDeliveryRoute.localeCompare(
                            groupedDeliveries[customerIdB][0]
                              .customerDeliveryRoute
                          )
                        : 0;
                    })
                    .map((customerId) => {
                      if (!groupedDeliveries) {
                        return null;
                      }
                      const delivery = groupedDeliveries[customerId][0];
                      return (
                        <>
                          <TableRow className={classes.customerRow}>
                            <TableCell></TableCell>
                            <TableCell
                              className={classes.headerCell}
                              colSpan={2}
                            >
                              {delivery.customerName}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.headerCell}
                              colSpan={3}
                            >
                              Order Total:{" "}
                              {formatCurrency(
                                groupedDeliveries[customerId].reduce(
                                  (total, delivery) =>
                                    total + delivery.price * delivery.quantity,
                                  0
                                ),
                                grower.CountryISO
                              )}
                            </TableCell>
                          </TableRow>
                          {(delivery.customerAddressLine1 ||
                            delivery.customerDeliveryRoute) && (
                            <TableRow className={classes.customerRow}>
                              <TableCell></TableCell>
                              <TableCell
                                className={classes.headerCell}
                                colSpan={2}
                              >
                                {delivery.customerAddressLine1}
                              </TableCell>
                              <TableCell
                                colSpan={3}
                                align="right"
                                className={classes.headerCell}
                              >
                                {delivery.customerDeliveryRoute}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow className={classes.dataHeaderRow}>
                            <TableCell
                              classes={{
                                root: classes.checkBoxCell,
                              }}
                            ></TableCell>
                            <TableCell
                              classes={{
                                root: classes.productCell,
                              }}
                              className={classes.headerCell}
                            >
                              Product
                            </TableCell>
                            <TableCell
                              align="center"
                              classes={{
                                root: classes.sizeCell,
                              }}
                              className={classes.headerCell}
                            >
                              Size
                            </TableCell>
                            <TableCell
                              align="center"
                              classes={{
                                root: classes.secondaryCell,
                              }}
                              className={classes.headerCell}
                            >
                              Quantity
                            </TableCell>
                            <TableCell
                              align="center"
                              classes={{
                                root: classes.secondaryCell,
                              }}
                              className={classes.headerCell}
                            >
                              Price
                            </TableCell>
                            <TableCell
                              align="center"
                              classes={{
                                root: classes.secondaryCell,
                              }}
                              className={classes.headerCell}
                            >
                              Total
                            </TableCell>
                          </TableRow>
                          {groupedDeliveries[customerId].map((delivery) => (
                            <TableRow>
                              <TableCell>
                                <CheckBoxOutlineBlankIcon />
                              </TableCell>
                              <TableCell>{delivery.productName}</TableCell>
                              <TableCell align="center">
                                {delivery.productSize}
                                {delivery.productWeight
                                  ? ` (${
                                      delivery.productWeight
                                    } ${unitSystemLabel(grower)})`
                                  : ``}
                              </TableCell>
                              <TableCell align="center">
                                {delivery.quantity}
                              </TableCell>
                              <TableCell align="center">
                                {formatCurrency(
                                  delivery.price,
                                  grower.CountryISO
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {formatCurrency(
                                  delivery.quantity * delivery.price,
                                  grower.CountryISO
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow></TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">
              No deliveries on{" "}
              {date.toLocaleString({ month: "long", day: "numeric" })}.
            </Typography>
          )}
        </Box>
      )}
      <LoadingBackdrop open={busy} />
      <UpgradePlanDialog
        open={openUpgradePlanDialog}
        onClickCancel={onClickCancelUpgradePlan}
      />
    </Page>
  );
}

function groupBy(objectArray: any[], property: string) {
  return objectArray.reduce(function (acc, obj) {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}
