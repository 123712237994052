import { useState, useEffect, useContext } from "react";
import {
  useHistory,
  Route,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
import { EmailVerificationPage } from "../pages/email-verification-page";
import { LandingPage } from "../pages/landing-page";
import { LogInPage } from "../pages/log-In-page";
import { ForgotPassword } from "../pages/forgot-password-page";
import { ResetPasswordPage } from "../pages/reset-password-page";
import { SignUpPage } from "../pages/sign-up-page";
import { InitialSetupPage } from "../pages/initial-setup-page";
import HomePage from "../pages/home-page";
import { VerifyEmailPage } from "../pages/verify-email-page";
import React from "react";
import { Grower } from "../models/grower";
import { User } from "../models/user";
import { usersApi } from "../services/api/users";
import { sessionsApi } from "../services/api/sessions";
import { billingApi } from "../services/api/billing";
import LoadingBackdrop from "./loading-backdrop";
import axios, { AxiosError } from "axios";
import { SubscriptionPastDueDialog } from "./sub-past-due-dialog";
import {
  SubscriptionErrorDialog,
  SubscriptionActions,
} from "./subscription-error-dialog";
import * as Sentry from "@sentry/react";
import { sentryDSN } from "..";
import mixpanel from "mixpanel-browser";
import { subscriptionsApi } from "../services/api/subscriptions";

interface State {
  isLoggedIn: boolean;
  isSetup: boolean;
  from?: string;
  context?: {
    grower?: Grower;
    user: User;
  };
}

export const ErrorContext = React.createContext({
  handleError: (error: any) => {
    console.error(error);
  },
});

const AnonymousRoute = (props: {
  children: JSX.Element;
  state: State;
  exact?: boolean;
  path: string;
}) => {
  return (
    <Route exact={props.exact} path={props.path}>
      {props.state.isLoggedIn ? (
        <Redirect
          to={
            props.state.isSetup ? props.state.from || "/home" : "/initial-setup"
          }
        />
      ) : (
        <div>{props.children}</div>
      )}
    </Route>
  );
};

const AuthenticatedRoute = (props: {
  children: JSX.Element;
  state: State;
  exact?: boolean;
  path: string;
}) => (
  <Route
    exact={props.exact}
    path={props.path}
    render={({ location }) =>
      props.state.isLoggedIn ? (
        props.children
      ) : (
        <Redirect
          to={{
            pathname: "/log-in",
            state: { from: location.pathname },
          }}
        />
      )
    }
  />
);

export const UserContext = React.createContext<{
  user: User;
  grower?: Grower;
  state: State;
  setState: (state: State) => void;
}>(undefined!);

enum ForbiddenReasons {
  Subscription = "subscription",
}

export function AppRoutes() {
  const [state, setState] = useState<State | null>(null);
  const [busy, setBusy] = useState(false);
  const history = useHistory();
  const [
    openSubscriptionErrorDialog,
    setOpenSubscriptionErrorDialog,
  ] = useState(false);
  const [
    openSubscriptionPastDueDialog,
    setOpenSubscriptionPastDueDialog,
  ] = useState(false);
  const [subscriptionErrorGrower, setSubscriptionErrorGrower] = useState<
    Grower | undefined
  >();

  const user = useContext(UserContext);
  const location = useLocation();

  const handleError = (error: any) => {
    console.error(error);

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{
        Reason?: string;
        Data?: Grower;
        message?: string;
      }>;
      const resp = axiosError.response;

      if (!resp) {
        // Network error
        alert(
          "Sorry, something went wrong.  Check your network connection and then try again."
        );
        return;
      } else if (resp.status === 401) {
        setState({
          isLoggedIn: false,
          isSetup: false,
          context: undefined,
          from: location.pathname,
        });
        return;
      } else if (resp.status === 403) {
        switch (resp.data.Reason) {
          case ForbiddenReasons.Subscription:
            setSubscriptionErrorGrower(resp.data.Data as Grower);
            setOpenSubscriptionErrorDialog(true);
            return;
          default:
          // fallthrough
        }
      } else if (resp.status === 409) {
        alert(
          resp.data.message ||
            "Sorry, the operation failed because it would create a conflict.  If you're trying to delete something, make sure it is not in use elsewhere."
        );
        return;
      } else if (resp.status >= 500) {
        if (process.env.NODE_ENV === "production") {
          // Server errors are captured there and here we'll show a report
          // dialog and link it to the backend error event.  Note, this report
          // is associated with the backend DSN.
          const eventId = resp.headers["x-sentry-event-id"];

          Sentry.showReportDialog({
            dsn:
              "https://36917a9923684448ae2708697943317c@o1060572.ingest.sentry.io/6052365",
            eventId,
            user: user
              ? {
                  name: user.user.Name,
                  email: user.user.Email,
                }
              : undefined,
          });
          return;
        }
      }
    }

    if (process.env.NODE_ENV === "production") {
      // Any other Axios error will be captured and a report dialog will be shown
      // and associated with the error event.

      const eventId = Sentry.captureException(error);

      Sentry.showReportDialog({
        dsn: sentryDSN,
        eventId,
        user: user
          ? {
              name: user.user.Name,
              email: user.user.Email,
            }
          : undefined,
      });

      return;
    }

    // Sentry is not enabled
    alert(error);
  };

  const doSubscriptionAction = async (action: SubscriptionActions) => {
    setBusy(true);
    setOpenSubscriptionErrorDialog(false);

    try {
      const { url } = await subscriptionsApi.post({
        action: action.toString(),
      });
      if (url) {
        window.location.assign(url);
      } else {
        window.location.reload();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const goToBillingPortal = async () => {
    setBusy(true);
    try {
      const session = await billingApi.createPortalSession();
      window.location.assign(session.Url);
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const handleSubscriptionErrorDialogOnClickGoToBillingPortal = async () => {
    setOpenSubscriptionErrorDialog(false);
    goToBillingPortal();
  };

  const mixpanelIdentifyUser = (user: User) => {
    mixpanel.identify(user.id.toString());
    mixpanel.people.set({
      $email: user.Email,
      $name: user.Name,
      workflow: user.Growers.length > 0 ? user.Growers[0].Workflow : undefined,
    });
  };

  useEffect(() => {
    async function getState() {
      try {
        const user = await loadUser();

        Sentry.setUser({ email: user.Email });

        mixpanelIdentifyUser(user);

        const isPastDue =
          user.Growers.length > 0 &&
          user.Growers[0].StripeSubscriptionStatus === "past_due";

        if (isPastDue) {
          setOpenSubscriptionPastDueDialog(true);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 401) {
            setState({
              isLoggedIn: false,
              isSetup: false,
              context: undefined,
            });
            return;
          }
        }
        handleError(error);
      }
    }
    getState();
  }, []);

  const handleLogIn = async (from?: string) => {
    try {
      const user = await loadUser(from);

      Sentry.setUser({ email: user.Email });

      mixpanelIdentifyUser(user);
      mixpanel.track("Log In");
    } catch (error) {
      handleError(error);
    }
  };

  const handleSetup = async () => {
    try {
      await loadUser();
    } catch (error) {
      handleError(error);
    }
  };

  const handleSignUp = async () => {
    try {
      const user = await loadUser();

      Sentry.setUser({ email: user.Email });

      mixpanelIdentifyUser(user);
      mixpanel.track("Sign Up");

      if (LeadDyno) {
        LeadDyno.recordLead(user.Email);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleLogOut = async () => {
    try {
      await sessionsApi.end();

      setState({
        isLoggedIn: false,
        isSetup: false,
        context: undefined,
      });

      Sentry.setUser(null);

      mixpanel.reset();
    } catch (error) {
      handleError(error);
    }
  };

  const handleEmailVerification = async () => {
    mixpanel.track("Verify Email");

    if (!state?.isLoggedIn) {
      history.replace("/log-in");
      return;
    }
    try {
      await loadUser();
      history.replace("/home");
    } catch (error) {
      handleError(error);
    }
  };

  const loadUser = async (from?: string): Promise<User> => {
    const user = await usersApi.get();
    setStateForUser(user, from);
    return user;
  };

  const setStateForUser = (user: User, from?: string): void => {
    setState({
      isLoggedIn: true,
      isSetup: user.Growers.length > 0,
      context: {
        grower: user.Growers.length > 0 ? user.Growers[0] : undefined,
        user,
      },
      from,
    });
  };

  const handleSubscriptionErrorDialogOnClickLogOut = () => {
    setOpenSubscriptionErrorDialog(false);
    handleLogOut();
  };

  const handleSubscriptionPastDueDialogOnClickClose = () => {
    setOpenSubscriptionPastDueDialog(false);
  };

  const handleSubscriptionPastDueDialogOnClickGoToBillingPortal = () => {
    setOpenSubscriptionPastDueDialog(false);
    goToBillingPortal();
  };

  return (
    <ErrorContext.Provider
      value={{
        handleError,
      }}
    >
      <div>
        {state && (
          <Switch>
            <Route path="/email-verification/:id/:token">
              <EmailVerificationPage onComplete={handleEmailVerification} />
            </Route>
            <AnonymousRoute state={state} exact path="/">
              <LandingPage />
            </AnonymousRoute>
            <AnonymousRoute state={state} path="/log-in">
              <LogInPage onLoggedIn={handleLogIn} />
            </AnonymousRoute>
            <AnonymousRoute state={state} path="/forgot-password">
              <ForgotPassword />
            </AnonymousRoute>
            <AnonymousRoute state={state} path="/reset-password">
              <ResetPasswordPage />
            </AnonymousRoute>
            <AnonymousRoute state={state} path="/sign-up">
              <SignUpPage onSignUp={handleSignUp} />
            </AnonymousRoute>
            <AuthenticatedRoute state={state} path="/initial-setup">
              {state.isSetup ? (
                <Redirect to="/home" />
              ) : (
                <InitialSetupPage onSetup={handleSetup} />
              )}
            </AuthenticatedRoute>
            <AuthenticatedRoute state={state} path="/home">
              {state.context && state.isSetup ? (
                <UserContext.Provider
                  value={{
                    user: state.context.user,
                    grower: state.context.grower,
                    state,
                    setState: setState,
                  }}
                >
                  <HomePage onLogOut={handleLogOut} />
                </UserContext.Provider>
              ) : (
                <Redirect to="/initial-setup" />
              )}
            </AuthenticatedRoute>
            <AuthenticatedRoute state={state} path="/verify-email">
              {(state.context &&
                (state.context?.user.EmailVerified ? (
                  <Redirect to="/home" />
                ) : (
                  <UserContext.Provider
                    value={{
                      user: state.context.user,
                      grower: state.context.grower,
                      state,
                      setState,
                    }}
                  >
                    <VerifyEmailPage />
                  </UserContext.Provider>
                ))) || <div>Error</div>}
            </AuthenticatedRoute>
          </Switch>
        )}
        {openSubscriptionErrorDialog && (
          <SubscriptionErrorDialog
            onClickGoToBillingPortal={
              handleSubscriptionErrorDialogOnClickGoToBillingPortal
            }
            onClickLogOut={handleSubscriptionErrorDialogOnClickLogOut}
            onClickSubscriptionAction={doSubscriptionAction}
            grower={subscriptionErrorGrower}
          />
        )}
        {openSubscriptionPastDueDialog && (
          <SubscriptionPastDueDialog
            onClickClose={handleSubscriptionPastDueDialogOnClickClose}
            onClickGoToBillingPortal={
              handleSubscriptionPastDueDialogOnClickGoToBillingPortal
            }
          />
        )}
      </div>
      <LoadingBackdrop open={busy} />
    </ErrorContext.Provider>
  );
}
