import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import LoadingBackdrop from "../components/loading-backdrop";
import { Product } from "../models/product";
import { productsApi } from "../services/api/products";
import Page from "../components/page";
import { Order } from "../models/order";
import { Customer } from "../models/customer";
import { customersApi } from "../services/api/customers";
import OrderForm, {
  OrderFormValues,
  OrderRecurType,
} from "../components/order-form";
import { DateTime } from "luxon";
import { ordersApi } from "../services/api/orders";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../components/confirm-dialog";
import { sizesApi } from "../services/api/sizes";
import { UserContext, ErrorContext } from "../components/app-routes";
import { formatCurrency, toCurrency } from "./currency";
import { v4 } from "uuid";
import mixpanel from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useStyles = makeStyles((theme) => ({
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

interface Data {
  customers: Customer[];
  products: Product[];
  order: Order;
}

interface ConfirmUpdateRecurringOrderDialogProps {
  open: boolean;
  title: string;
  description: string;
  onClose: (confirm: boolean) => void;
}

function ConfirmUpdateRecurringOrderDialog(
  props: ConfirmUpdateRecurringOrderDialogProps
) {
  const { open, onClose, title, description } = props;

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export enum UpdateRecurringOrderMethods {
  None = "",
  ThisOrder = "this",
  ThisAndFollowingOrders = "thisAndFollowing",
}

interface UpdateRecurringOrderDialogProps {
  open: boolean;
  onClose: (value?: UpdateRecurringOrderMethods) => void;
  title: string;
}

export function UpdateRecurringOrderDialog(
  props: UpdateRecurringOrderDialogProps
) {
  const { open, onClose } = props;
  const [value, setValue] = useState<UpdateRecurringOrderMethods>(
    UpdateRecurringOrderMethods.None
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      (event.target as HTMLInputElement).value as UpdateRecurringOrderMethods
    );
  };

  const handleClose = (value?: UpdateRecurringOrderMethods) => {
    setValue(UpdateRecurringOrderMethods.None);
    onClose(value);
  };

  return (
    <Dialog disableEscapeKeyDown maxWidth="xs" open={open}>
      <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {[
            {
              value: UpdateRecurringOrderMethods.ThisOrder,
              label: "This Order",
            },
            {
              value: UpdateRecurringOrderMethods.ThisAndFollowingOrders,
              label: "This and Following Orders",
            },
          ].map((e) => (
            <FormControlLabel
              value={e.value}
              key={e.value}
              control={<Radio />}
              label={e.label}
            />
          ))}
        </RadioGroup>
        <Typography variant="caption">
          Select <i>This and Following Orders</i> to update the end date of a
          recurring order.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClose(value)} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function OrderPage() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState<Data | null>(null);
  const [
    openUpdateRecurringOrderDialog,
    setOpenUpdateRecurringOrderDialog,
  ] = useState(false);
  const [
    openConfirmUpdateThisRecurringOrderDialog,
    setOpenConfirmUpdateThisRecurringOrderDialog,
  ] = useState(false);
  const [
    openConfirmUpdateThisAndFollowingRecurringOrderDialog,
    setOpenConfirmUpdateThisAndFollowingRecurringOrderDialog,
  ] = useState(false);
  const [updateRecurringOrderMethod, setUpdateRecurringOrderMethod] = useState<
    UpdateRecurringOrderMethods | undefined
  >();
  const [submittedFormValues, setSubmittedFormValues] = useState<
    OrderFormValues | undefined
  >();
  const [
    openConfirmTrashOrderDialog,
    setOpenConfirmTrashOrderDialog,
  ] = useState(false);
  const [
    openTrashRecurringOrderDialog,
    setOpenTrashRecurringOrderDialog,
  ] = useState(false);
  const [saved, setSaved] = useState(false);

  const history = useHistory();
  const theme = useTheme();

  const { grower } = useContext(UserContext);
  const { handleError } = useContext(ErrorContext);

  if (!grower) {
    throw new Error("Invalid UserContext");
  }

  useEffect(() => {
    async function getData() {
      setBusy(true);
      try {
        const results = await Promise.all([
          productsApi.getAll(),
          customersApi.getAll(),
          ordersApi.getSingle(parseInt(id)),
          sizesApi.getAll(),
        ]);
        setData({
          products: results[0],
          customers: results[1],
          order: results[2],
        });
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getData();
  }, [id]);

  const handleOnCloseConfirmUpdateRecurringOrderDialog = (confirm: boolean) => {
    setOpenConfirmUpdateThisRecurringOrderDialog(false);
    setOpenConfirmUpdateThisAndFollowingRecurringOrderDialog(false);
    if (!confirm) {
      return;
    }
    if (!submittedFormValues) {
      throw new Error("Invalid state");
    }
    updateOrder(submittedFormValues, updateRecurringOrderMethod);
    setUpdateRecurringOrderMethod(undefined);
  };

  const handleOnCloseUpdateRecurringOrderDialog = (
    value?: UpdateRecurringOrderMethods
  ) => {
    setOpenUpdateRecurringOrderDialog(false);

    if (!value) {
      return;
    }

    setUpdateRecurringOrderMethod(value);

    switch (value) {
      case UpdateRecurringOrderMethods.ThisOrder:
        setOpenConfirmUpdateThisRecurringOrderDialog(true);
        break;
      case UpdateRecurringOrderMethods.ThisAndFollowingOrders:
        setOpenConfirmUpdateThisAndFollowingRecurringOrderDialog(true);
        break;
    }
  };

  const handleSubmit = async (values: OrderFormValues) => {
    if (values.recur?.type !== OrderRecurType.None) {
      setSubmittedFormValues(values);
      setOpenUpdateRecurringOrderDialog(true);
    } else {
      updateOrder(values);
    }
  };

  const handleOnClickTrashOrder = () => {
    if (data?.order.recur) {
      setOpenTrashRecurringOrderDialog(true);
    } else {
      setOpenConfirmTrashOrderDialog(true);
    }
  };

  const handleOnClickCancel = () => {
    goBackOrTo(history, "/home/orders");
  };

  const handleOnCloseConfirmTrashOrderDialog = (ok?: boolean) => {
    setOpenConfirmTrashOrderDialog(false);
    if (!ok) {
      return;
    }
    trashOrder();
  };

  const handleOnCloseTrashRecurringOrderDialog = (
    value?: UpdateRecurringOrderMethods
  ) => {
    setOpenTrashRecurringOrderDialog(false);

    if (!value) {
      return;
    }

    trashOrder(value);
  };

  const trashOrder = async (value?: UpdateRecurringOrderMethods) => {
    if (!data) {
      throw new Error("Invalid state");
    }
    setBusy(true);
    try {
      await ordersApi.trash(
        data.order.id,
        value === UpdateRecurringOrderMethods.ThisAndFollowingOrders
          ? {
              recurring: "thisAndFollowing",
            }
          : undefined
      );

      goBackOrTo(history, "/home/orders");

      mixpanel.track("Delete Order");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const updateOrder = async (
    values: OrderFormValues,
    updateRecurringOrderMethod?: UpdateRecurringOrderMethods
  ) => {
    if (!data) {
      throw new Error("Invalid state");
    }
    setBusy(true);
    try {
      await ordersApi.update(
        {
          id: data.order.id,
          customerId: parseInt(values.customerId),
          harvestDate: values.date?.toISODate() || "",
          recur: values.recur
            ? {
                id: parseInt(values.recur.id),
                endDate: values.recur.endDate?.toISODate() || "",
                type: values.recur.type,
              }
            : undefined,
          orderProducts: values.orderProducts.map((oP) => ({
            price: toCurrency(oP.price, grower.CountryISO).intValue,
            productId: parseInt(oP.productId),
            quantity: parseInt(oP.quantity),
            productSizeId: parseInt(oP.productSizeId),
          })),
          customer: (null as unknown) as Customer,
          notes: values.notes,
        },
        updateRecurringOrderMethod ===
          UpdateRecurringOrderMethods.ThisAndFollowingOrders
          ? "following"
          : undefined
      );

      setSaved(true);

      mixpanel.track("Update Order");

      goBackOrTo(history, "/home/orders");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Page title={"Order"}>
      {data && (
        <>
          <div>
            <Button
              sx={{ marginBottom: theme.spacing(2) }}
              variant="contained"
              color="primary"
              component={Link}
              to={{
                pathname: "/home/orders/add",
                state: {
                  order: data.order,
                },
              }}
              onClick={() => mixpanel.track("Duplicate Order")}
              disabled={busy}
              startIcon={<ContentCopyIcon />}
            >
              Duplicate
            </Button>
          </div>

          <OrderForm
            initialValues={{
              customerId: data.order.customerId.toString(),
              dateType: "harvestDate",
              date: DateTime.fromISO(data.order.harvestDate),
              orderProducts: data.order.orderProducts.map((oP) => ({
                id: v4(),
                productId: oP.productId.toString(),
                productSizeId: oP.productSizeId.toString(),
                quantity: oP.quantity.toString(),
                price: formatCurrency(oP.price, grower.CountryISO, {
                  symbol: "",
                  separator: "",
                }),
              })),
              recur: data.order.recur
                ? {
                    id: data.order.recur.id.toString(),
                    endDate: DateTime.fromISO(data.order.recur.endDate),
                    type: data.order.recur.type,
                  }
                : {
                    id: "",
                    type: OrderRecurType.None,
                    endDate: null,
                  },
              notes: data.order.notes,
            }}
            onSubmit={handleSubmit}
            customers={data.customers}
            products={data.products}
            editing={true}
            saved={saved}
          >
            <Box mt={2} className={classes.actions}>
              <Button
                variant="outlined"
                disabled={busy}
                onClick={handleOnClickCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={busy}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={busy}
                startIcon={<DeleteIcon />}
                onClick={handleOnClickTrashOrder}
              >
                Trash
              </Button>
            </Box>
          </OrderForm>
        </>
      )}
      <LoadingBackdrop open={busy} />
      <UpdateRecurringOrderDialog
        open={openUpdateRecurringOrderDialog}
        onClose={handleOnCloseUpdateRecurringOrderDialog}
        title={"Update Recurring Order"}
      />
      <ConfirmUpdateRecurringOrderDialog
        open={openConfirmUpdateThisRecurringOrderDialog}
        title="Update This Order?"
        description="Updating this order will permanently remove it from the recurring order.  This order will no longer be effected by changes to the recurring order."
        onClose={handleOnCloseConfirmUpdateRecurringOrderDialog}
      />
      <ConfirmUpdateRecurringOrderDialog
        open={openConfirmUpdateThisAndFollowingRecurringOrderDialog}
        title="Update This and Following Orders?"
        description="Updating this and following orders will override any earlier changes to these orders."
        onClose={handleOnCloseConfirmUpdateRecurringOrderDialog}
      />
      <ConfirmDialog
        title="Trash Order"
        description={`Are you sure you want to trash this order?`}
        open={openConfirmTrashOrderDialog}
        onClose={handleOnCloseConfirmTrashOrderDialog}
      ></ConfirmDialog>
      <UpdateRecurringOrderDialog
        open={openTrashRecurringOrderDialog}
        onClose={handleOnCloseTrashRecurringOrderDialog}
        title={"Trash Recurring Order"}
      />
    </Page>
  );
}
